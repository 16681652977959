import { createStore } from 'vuex'

export default createStore({
  state: {
    lenguaje: {en: 'English',   es: 'Inglés',     code:'en'},
    lenguajes: [
        {en: 'English',   es: 'Inglés',     code:'en'},
        {en: 'Spanish',   es: 'Español',    code:'es'},
    ],

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
