import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/scss/style.scss'
import { createI18nPlugin, useI18nPlugin } from '@unify/vuex-i18n';
import En from './language/en.json';
import Es from './language/es.json';

const app = createApp(App).use(store);

app.use(createI18nPlugin(store));
const i18n = useI18nPlugin();
i18n.add('en', En);
i18n.add('es', Es);
i18n.set('en');

app.use(router);

app.mount('#app');