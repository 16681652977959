import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  
  {
    path: '/',
    name: 'Preview',
    meta: {
      title: 'Preview'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Preview.vue')
  },
  {
    path: "/home-light",
    name: "HomeLight",
    meta: {
      title: 'Home Light'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeLight.vue')
  },
  {
    path: "/home-dark",
    name: "HomeDark",
    meta: {
      title: 'Home Dark'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeDark.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
