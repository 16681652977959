<template>
  <div id="app" class="tokyo_tm_all_wrap">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    components: {
    },
  };  
</script>
